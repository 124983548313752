import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import dayjs from "dayjs";
import Slider from "react-slick";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";
import withPreview from "../utility/with-preview";
import Icon from "../components/elements/icon-svg";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";
import MagazineSearchbox from "../components/magazine/magazine-searchbox";

const ArrowBtn = ({ slideCount, currentSlide, ...props }) => (
  <button type="button" {...props}>
    <Icon name="long-arrow-right-solid" />
  </button>
);

const tagLink = (tag) => {
  const params = new URLSearchParams();
  params.set("tag", tag);
  return `/magazine/?${params.toString()}`;
};

const authorLink = (authorName) => {
  const params = new URLSearchParams();
  params.set("author", authorName.toUpperCase());
  return `/magazine/?${params.toString()}`;
};

const MagazineDetailPage = ({ data: { page }, location }) => {
  const {
    mainTitle,
    abstract,
    liferayFields,
    smartphoneDetailImage,
    sectionTitle,
    bottoneCta,
    authorName,
    contentDetailImage,
  } = page;
  useInitDataLayer(location);
  useHandleRecaptchaBadge(false);

  const currentURL = location?.href;

  const slidesToShow = 3;
  const [currentSlide, setCurrentSlide] = useState(0);
  const related = liferayFields.relatedContents;
  const totalSlidesNumber = related ? related.length - slidesToShow + 1 : 0;

  const settings = {
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn />,
    nextArrow: <ArrowBtn />,
    afterChange: (index) => {
      setCurrentSlide(index);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  const ogImage = useMemo(() => {
    const sectionWithImages = page.sectionTitle?.filter(
      (item) => item?.content?.articleImage?.node
    );
    return page.smallCardImage?.node
      ? page.smallCardImage.node
      : sectionWithImages?.[0]?.content?.articleImage?.node;
  }, [page]);

  return (
    <Layout location={location} showChatWidget={false}>
      <Seo
        title={page.liferayFields.title}
        description={page.liferayFields.description}
        ogDescription={page.abstractText}
        ogImage={ogImage}
        path={page.link}
        ogType="article"
      />
      <div className="magazine-detail wrapper-container">
        {abstract?.value && (
          <div className="d-none" dangerouslySetInnerHTML={{ __html: abstract.value }} />
        )}
        <div className="row mx-0">
          <div className="col-12">
            <div className="magazine-detail__sticky d-flex">
              <div className="magazine-detail__stickybar magazine-detail__stickybar--left">
                <div className="magazine-detail__social-box">
                  {liferayFields?.keywords?.length > 0 && (
                    <div className="magazine-detail__social-title">TAG</div>
                  )}
                  {liferayFields?.keywords?.map((tag, i) => (
                    <Link to={tagLink(tag)} key={i} className="magazine-detail__social-tag">
                      #{tag}
                    </Link>
                  ))}
                  <div className="magazine-detail__social-title">CONDIVIDI</div>
                  <Link
                    to={`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`}
                    className="magazine-detail__social-link"
                    aria-label="Condividi via Facebook"
                  >
                    <Icon name="facebook-solid" className="magazine-detail__social-icon" />
                  </Link>
                  <Link
                    to={`https://twitter.com/intent/tweet?text=${currentURL}`}
                    className="magazine-detail__social-link"
                    aria-label="Condividi via Twitter"
                  >
                    <Icon name="twitter-x" className="magazine-detail__social-icon" />
                  </Link>
                  <Link
                    to={`mailto:?to=&subject=ENGIE Magazine - ${mainTitle?.value} &body=Leggi l'articolo: ${currentURL}`}
                    className="magazine-detail__social-link"
                    aria-label="Condividi via e-mail"
                  >
                    <Icon name="envelope" className="magazine-detail__social-icon" />
                  </Link>
                  <Link
                    to={`https://wa.me/?text=${currentURL}`}
                    className="magazine-detail__social-link"
                    aria-label="Condividi via Whatsapp"
                  >
                    <Icon name="whatsapp" className="magazine-detail__social-icon" />
                  </Link>
                </div>
                {/* <#-- form --> sideBlockTitle mai valorizzato
                  <#if .vars['side_block_title'].getData()?has_content && .vars['side_block_title'].getChild('side_block_text').getData()?has_content && .vars['side_block_title'].getChild('side_block_bg_image').getData()?has_content>
                      <div class="box-form hidden-xs hidden-sm" style="background-image: url('${.vars['side_block_title'].getChild('side_block_bg_image').getData()}')">
                          <div class="box-form__title">${.vars['side_block_title'].getData()}</div>
                          <div class="box-form__text">${.vars['side_block_title'].getChild('side_block_text').getData()}</div>
                          <form action="#" method="post" class="box-form__form">
                              <div class="box-form__wrapper">
                                  <input id="box_email" type="text" value="" placeholder="Inserisci la tua mail" class="box-form__input" />
                                  <input id="box_companyId" name="companyId" type="hidden" value="10201" />
                                  <input id="box_groupId" name="groupId" type="hidden" value="1416173" />
                                  <button type="submit" class="box-form__button" style="display: none;">Invia</button>
                              </div>
                          </form>
                          <#-- thank you -->
                              <div id="box_typ" style="font-size: 16px; display: none !important; margin-top: 50px;">
                                  <div class="" style="align-content: end;">Grazie per averci lasciato i tuoi dati!</div>
                              </div>
                      </div>
                  </#if> */}

                {authorName?.value && (
                  <div className="magazine-detail__author-box">
                    <div className="magazine-detail__author-img">
                      {authorName?.content?.authorImage?.node && (
                        <GatsbyImage
                          image={getImage(authorName.content.authorImage.node)}
                          alt={authorName.value}
                          imgStyle={{ borderRadius: "50%" }}
                        />
                      )}
                    </div>
                    <div className="magazine-detail__author-label">scritto da</div>
                    <div className="magazine-detail__author-name">{authorName.value}</div>
                    <div className="magazine-detail__author-role">
                      {authorName?.content?.authorQualification?.value}
                    </div>

                    <Link
                      to={authorLink(authorName?.value)}
                      title="Vai"
                      className="magazine-detail__author-goto"
                    >
                      <Icon name="long-arrow-right-solid" />
                    </Link>
                  </div>
                )}
                <MagazineSearchbox location={location} magazineDetail={true} />
              </div>
              <div className="magazine-detail__content">
                <div className="magazine-detail__breadcrumb d-flex flex-wrap">
                  <ol className="magazine-detail__breadcrumb-list">
                    <li className="magazine-detail__breadcrumb-list-item">
                      <Link to="/" className="magazine-detail__breadcrumb-link">
                        Casa Engie
                      </Link>
                      {" > "}
                    </li>
                    <li className="magazine-detail__breadcrumb-list-item">
                      <Link to="/magazine" className="magazine-detail__breadcrumb-link">
                        Magazine
                      </Link>
                      {" > "}
                    </li>
                    <li className="magazine-detail__breadcrumb-list-item">
                      <span className="magazine-detail__breadcrumb-current">
                        {mainTitle?.value}
                      </span>
                    </li>
                  </ol>
                </div>
                <article className="magazine-detail__article">
                  <div className="row mx-0">
                    <div className="col-3 d-lg-none">
                      <Link to="/magazine" className="magazine-detail__back">
                        Back
                      </Link>
                    </div>
                    <div className="col-9 col-md-12 px-0">
                      <div className="magazine-detail__article-info">
                        <span className="magazine-detail__article-category">
                          {liferayFields?.taxonomyCategoryNames?.map((category) => category)}
                        </span>
                        <span className="magazine-detail__article-date">
                          {liferayFields?.datePublished &&
                            dayjs(liferayFields.datePublished).format("DD MM YYYY")}
                        </span>
                      </div>
                    </div>
                    <h1 className="magazine-detail__title">{mainTitle?.value}</h1>
                    {smartphoneDetailImage?.node && (
                      <div className="d-flex justify-content-center w-100 d-lg-none">
                        <GatsbyImage
                          image={getImage(smartphoneDetailImage.node)}
                          alt={mainTitle?.value}
                          className="magazine-detail__img-sm"
                        />
                      </div>
                    )}
                    {abstract?.value && (
                      <div
                        className="magazine-detail__abstract"
                        dangerouslySetInnerHTML={{ __html: abstract.value }}
                      />
                    )}
                    {sectionTitle?.map((section, i) => (
                      <div className="magazine-detail__section" key={i}>
                        {section?.value && (
                          <div
                            className="magazine-detail__text magazine-detail__text--big"
                            dangerouslySetInnerHTML={{ __html: section.value }}
                            style={{ "--color": section?.content?.sectionColor?.value }}
                          />
                        )}
                        {section?.content?.sectionText?.value && (
                          <div
                            className="magazine-detail__text"
                            dangerouslySetInnerHTML={{ __html: section.content.sectionText.value }}
                          />
                        )}
                        {(section?.content?.articleImage?.node ||
                          section?.content?.citation?.value) && (
                          <div className="magazine-detail__img text-center">
                            {section?.content?.citation?.value && (
                              <h2
                                className="magazine-detail__subject"
                                dangerouslySetInnerHTML={{ __html: section.content.citation.value }} // mai valorizzato
                              />
                            )}
                            {section?.content?.articleImage?.node && (
                              <GatsbyImage
                                image={getImage(section.content.articleImage.node)}
                                alt={section?.value}
                                className="w-50"
                                imgClassName="d-block m-auto"
                              />
                            )}
                          </div>
                        )}
                        {/* Campi mai valorizzati, da inserire ? 
                            <#if .vars['side_block_title'].getData()?has_content && .vars['side_block_title'].getChild('side_block_text').getData()?has_content &&
                                .vars['side_block_title'].getChild('side_block_bg_image').getData()?has_content>
                                <div class="box-form visible-xs-block visible-sm-block" style="background-image: url('${.vars['side_block_title'].getChild('side_block_bg_image').getData()}')">
                                    <div class="box-form__title">${.vars['side_block_title'].getData()}</div>
                                    <div class="box-form__text">${.vars['side_block_title'].getChild('side_block_text').getData()}</div>
                                    <form action="#" method="post" class="box-form__form">
                                        <div class="box-form__wrapper">
                                            <input type="text" value="" placeholder="inserisci la tua mail" class="box-form__input" />
                                            <button type="submit" class="box-form__button">Invia</button>
                                        </div>
                                    </form>
                                </div>
                            </#if> */}
                      </div>
                    ))}
                    {bottoneCta?.value && bottoneCta?.content?.linkCta?.value && (
                      <div className="magazine-detail__cta-box">
                        <div className="row mx-0">
                          {bottoneCta?.content?.iconaCta?.node && (
                            <div className="col-sm-3">
                              <div className="magazine-detail__cta-elem d-flex flex-column justify-content-center align-items-center">
                                <GatsbyImage
                                  image={getImage(bottoneCta.content.iconaCta.node)} //iconaCta mai valorizzata
                                  alt={bottoneCta.value}
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className={classNames(
                              bottoneCta?.content?.iconaCta?.node ? "col-sm-8" : "col-sm-12"
                            )}
                          >
                            {bottoneCta?.content?.testoCta?.value && (
                              <div className="text-wrap text-center">
                                <p className="magazine-detail__cta-text">
                                  {bottoneCta.content.testoCta.value}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-100 text-center">
                            <Link
                              to={bottoneCta.content.linkCta.value}
                              target="_blank"
                              className="magazine-detail__cta-btn"
                              id={bottoneCta?.content?.idCta?.value}
                            >
                              {bottoneCta.value}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </article>
              </div>

              <div className="magazine-detail__stickybar magazine-detail__stickybar--right">
                {contentDetailImage?.node && (
                  <GatsbyImage
                    image={getImage(contentDetailImage.node)}
                    alt={mainTitle?.value}
                    className="magazine-detail__stickybar-img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="related">
        <div className="wrapper-container">
          <div className="row no-gutters">
            <div className="col-12 px-4">
              <div className="related__title d-lg-none">Leggi anche</div>
              <Slider className="related__slider" {...settings}>
                {related?.map((article, i) => (
                  <div className="related__slide pr-5 mt-3 mt-lg-0" key={i}>
                    <Link to={article.link} className="d-lg-flex">
                      {article?.smallCardImage?.node && (
                        <GatsbyImage
                          className="related__img"
                          image={getImage(article.smallCardImage.node)}
                          alt={article?.mainTitle?.value}
                        />
                      )}
                      <div className="related__text mr-5 mr-lg-0">
                        <div className="related__cat d-none d-lg-block">
                          {article?.liferayFields?.taxonomyCategoryNames}
                        </div>
                        <div className="related__article-title">{article?.mainTitle?.value}</div>
                        <Icon name="long-arrow-right-solid" />
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
              {totalSlidesNumber > 1 && (
                <div className="related__slide-counter d-none d-lg-block">
                  {currentSlide + 1} of {totalSlidesNumber}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withPreview(MagazineDetailPage, {
  subField: "page",
  fixed: true,
});

export const query = graphql`
  query ($id: String) {
    page: liferayMagazineStructure(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
        title
        description
        keywords
        taxonomyCategoryNames
        datePublished
        relatedContents {
          ... on LiferayMagazineStructure {
            link
            smallCardImage {
              node {
                gatsbyImageData(width: 495)
              }
            }
            mainTitle {
              value
            }
            liferayFields {
              taxonomyCategoryNames
            }
          }
        }
      }
      link
      mainTitle {
        value
      }
      shortTitle {
        value
      }
      titoloHtml {
        value
      }
      mainImage {
        node {
          publicURL
        }
      }
      contentDetailImage {
        node {
          gatsbyImageData(width: 900)
        }
      }
      smallCardImage {
        node {
          gatsbyImageData(width: 495)
          resize(width: 500) {
            src
            width
            height
          }
        }
      }
      mediumCardImage {
        node {
          publicURL
        }
      }
      largeCardImage {
        node {
          gatsbyImageData(width: 495)
        }
      }
      smartphoneDetailImage {
        node {
          gatsbyImageData(width: 300)
        }
      }
      sideBlockTitle {
        value
        content {
          sideBlockText {
            value
          }
          sideBlockBgImage {
            node {
              publicURL
            }
          }
        }
      }
      authorName {
        value
        content {
          authorImage {
            node {
              gatsbyImageData(width: 80)
            }
          }
          authorQualification {
            value
          }
        }
      }
      abstract {
        value
      }
      abstractText
      sectionTitle {
        value
        content {
          sectionColor {
            value
          }
          sectionText {
            value
          }
          articleImage {
            node {
              gatsbyImageData(width: 350)
              resize(width: 350) {
                src
                width
                height
              }
            }
          }
          citation {
            value
          }
        }
      }
      bottoneCta {
        value
        content {
          iconaCta {
            node {
              gatsbyImageData(width: 30)
            }
          }
          testoCta {
            value
          }
          linkCta {
            value
          }
          idCta {
            value
          }
        }
      }
    }
  }
`;
